body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p,
pre,
button {
  font-family: "Exo 2", sans-serif !important;
}

.work {
  padding: 10px 0;
  border: 2px solid #54ffff;
  border-radius: 5px;
  background: none;
  color: #54ffff;
  width: 120px;
  font-weight: 700;
  line-height: 1.2rem;
  cursor: pointer;
  -webkit-animation: work 2s infinite; /* Safari 4+ */
  -moz-animation: work 2s infinite; /* Fx 5+ */
  -o-animation: work 2s infinite; /* Opera 12+ */
  animation: work 2s infinite; /* IE 10+, Fx 29+ */
}

.work:hover {
  background: #54ffff;
  color: rgb(59, 59, 59);
}

.work:active {
  background: #3fc9c9;
  color: black;
}

@-webkit-keyframes work {
  0% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 16px rgba(84, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0);
  }
}

@-moz-keyframes work {
  0% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0.7);
    scale: (1.2);
  }

  70% {
    box-shadow: 0 0 0 16px rgba(84, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0);
  }
}

@-o-keyframes work {
  0% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0.7);
    scale: (1.2);
  }

  70% {
    box-shadow: 0 0 0 16px rgba(84, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0);
  }
}

@keyframes work {
  0% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0.7);
    scale: (1.2);
  }

  70% {
    box-shadow: 0 0 0 16px rgba(84, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(84, 255, 255, 0);
  }
}

@-webkit-keyframes cursor {
  from {
    border-bottom-color: rgba(84, 255, 255, 0.7);
  }
  to {
    border-bottom-color: transparent;
  }
}

@-moz-keyframes cursor {
  from {
    border-bottom-color: rgba(84, 255, 255, 0.7);
  }
  to {
    border-bottom-color: transparent;
  }
}

@-o-keyframes cursor {
  from {
    border-bottom-color: rgba(84, 255, 255, 0.7);
  }
  to {
    border-bottom-color: transparent;
  }
}

@keyframes cursor {
  from {
    border-bottom-color: rgba(84, 255, 255, 0.7);
  }
  to {
    border-bottom-color: transparent;
  }
}
